<template>
  <div class="auth-main viewContainer" >
    <v-container class="auth-container pa-4">
      <div>
        <v-tabs
          :color="($store.state.app.dark) ? 'white' : 'black'"
          class="content-tabs"
          background-color="transparent"
          fixed-tabs
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab :to="{ name: 'login' }" class="no-caps" :ripple="false">
            {{ $t('log_in') }}
            <!-- <v-icon size="24">mdi-account</v-icon> -->
          </v-tab>
          <v-tab :to="{ name: 'password.email' }" class="no-caps" :ripple="false">
            {{ $t('forgot_password') }}
            <!-- <v-icon size="24">mdi-key-variant</v-icon> -->
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" style="background-color: transparent;" class="mt-8">
          <v-tab-item value="tab-0">
            <v-card class="content-tabs-card px-8 py-12 rounded">
              <ValidationObserver ref="form1" v-slot="{ invalid }">
                <v-form 
                  :model="form1" 
                  @submit.prevent="submit('form1')"
                  autocomplete="off"
                  method="post"
                >
                    <h1 class="fg--text display-2 font-weight-medium mb-4">{{ $t('reset_password_title') }}</h1>
                    <p class="mb-8 fg--text subtitle-1 text--secondary">{{ $t('reset_password_info') }}</p>
                    <v-alert
                      :value="successReset"
                      type="success"
                      tile
                      class="mb-4"
                    >
                      {{ $t('reset_email_sent') }}
                    </v-alert>
                    <v-alert
                      :value="form1.hasError"
                      type="error"
                      tile
                      class="mb-4"
                    >
                      {{ errorMsg }}
                    </v-alert>
                    <x-text-field 
                      type="email"
                      v-model="form1.email"
                      ref="form1.email"
                      id="form1.email"
                      :label="$t('email')"
                      :placeholder="$t('email')"
                      :name="$t('email')"
                      rules="required|email"
                    />
                    <v-btn color="primary" class="no-caps mt-4" rounded block x-large depressed :loading="form1.loading" :disabled="form1.loading || invalid" type="submit">{{ $t('send_reset_link') }} <v-icon right>mdi-arrow-right</v-icon></v-btn>
                </v-form>
              </ValidationObserver>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  data: () => ({
    tab: 'tab-0',
    topBgImg: 'about:blank',
    topBgImgHeight: null,
    topBgImgDefaultHeight: 780,
    topBgImgAspectRation: 1.7778,
    successReset: false,
    topBgImgs: [],
    errorMsg: '',
    form1: {
      loading: false,
      email: null,
      hasError: false,
    }
  }),
  created () {
    
  },
  methods: {

    async submit (formName) {
      // Reset form validation
      this.$refs[formName].reset()

      // Form defaults
      let form = this[formName]
      form.hasError = false
      form.loading = true

      this.axios
        .post('/auth/password/reset', {
          locale: this.$i18n.locale,
          email: form.email
        })
        .then(response => {
          if (response.data.status === 'success') {
            this.successReset = true
            this.form1.email = ''
            this.$refs[formName].reset()
          } else if (typeof response.data.error !== 'undefined') {
            form.hasError = true
            this.errorMsg = response.data.error
          }
        })
        .catch(() => {
          form.hasError = true
        })
        .finally(() => form.loading = false)

    }
  },
  watch: {
    tab: function (val) {
      if (window.innerWidth > 760) {
        this.topBgImg = this.topBgImgs[0]
        this.topBgImgHeight = this.topBgImgDefaultHeight
        this.topBgImgAspectRation = 1.7778
      }
    }
  }
};
</script>
